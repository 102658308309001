import { withDevtools, withStorageSync } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { firstValueFrom, take } from 'rxjs';
import { GetItemsStateData, GetStateData, IStateData } from 'src/app/shared/interfaces/state/state';
import { nswagCatchOperator } from 'src/app/shared/operators/nswag-catch-operator';
import {
  HttpClientItem,
  HttpClientUnitOfMeasure,
  HttpClientVat,
  ItemActiveTypeEnum,
  ItemDto,
  PagedRequest,
  UnitOfMeasureDto,
  UnitOfMeasureGetAllOrFilteredRequest,
  VatDto,
  VatGetAllOrFilteredRequest,
} from 'src/app/shared/nswag.api';
import { EXPIRE_API_SECONDS } from 'src/app/shared/constants/api-priority.seconds';
import { ComparisonOperator, DynamicFilter, DynamicPage, ItemTypeEnum } from '../shared/devpos.api';
import { ConfigurationsStore } from './configurations-store';

export type ITEMS_QUERY = {
  pagedRequest: PagedRequest;
  type?: ItemTypeEnum | null | undefined;
  itemType?: ItemActiveTypeEnum | null | undefined;
  isStockable?: boolean | null | undefined;
  isAverageCost?: boolean | null | undefined;
  isBlocked?: boolean | null | undefined;
};

type ITEMS_STATE = {
  items: IStateData<ItemDto[] | undefined>[];
  ingredientsLoading: boolean;
  itemsNoDescriptionPair: IStateData<{ [key: string]: string } | undefined>;
  vats: IStateData<VatDto[] | undefined>;
  unitOfMeasureList?: IStateData<UnitOfMeasureDto[] | undefined>;
};

const INITIAL_ITEMS_STATE: ITEMS_STATE = {
  items: [
    {
      data: undefined,
      lastUpdatedDate: undefined,
      loading: false,
      query: undefined,
      hasNextPage: true,
    },
  ],
  ingredientsLoading: false,
  itemsNoDescriptionPair: {
    data: undefined,
    lastUpdatedDate: undefined,
    loading: false,
  },
  vats: {
    data: undefined,
    lastUpdatedDate: undefined,
    loading: false,
  },

  unitOfMeasureList: {
    data: undefined,
    lastUpdatedDate: undefined,
    loading: false,
  },
};

export const ItemsStore = signalStore(
  { providedIn: 'root' },
  withState(INITIAL_ITEMS_STATE),
  withComputed(state => ({
    loadItems: computed(() => state?.items()),
    queryIsLoading: computed(() => state?.items!()?.map(x => ({ queryString: JSON.stringify(x.query), loading: x.loading })) ?? []),
    queryHasNextPage: computed(() => state?.items!()?.map(x => ({ queryString: JSON.stringify(x.query), hasNextPage: x.hasNextPage })) ?? []),
    ingredientsIsLoading: computed(() => state?.ingredientsLoading),
    ingredients: computed(
      () =>
        (state?.items() ?? [])
          ?.filter(x => x.data)
          ?.map(x => x.data!)
          ?.flat() ?? [],
    ),
    itemsName: computed(() => state?.itemsNoDescriptionPair()),
    vatsList: computed(() => state?.vats()),
  })),
  withMethods(
    (
      store,
      httpClientItem = inject(HttpClientItem),
      httpClientVat = inject(HttpClientVat),
      httpClientUnitOfMeasure = inject(HttpClientUnitOfMeasure),
      configurationStore = inject(ConfigurationsStore),
      oldStore = inject(Store),
    ) => ({
      searchItems: async (force_skip: boolean = false, query?: ITEMS_QUERY) => {
        if (!force_skip) {
          const items = store.loadItems();
          const existingData = GetItemsStateData(
            items,
            moment(),
            EXPIRE_API_SECONDS.ITEM,
            configurationStore.skipCache().data,
            JSON.stringify(query),
          );
          if (existingData) return;
        }
        if (!query) return;
        // ne kete rast query do beje thirrje ne API, do shikojme nese ky query gjendet ne state (nese gjendet dmth qe ka skaduar koha).
        //Nese gjendet do bejme loading, perndryshe do shtojme element te ri ne state me loading true
        const indexOfQuery = (store.items?.() || []).findIndex(x => JSON.stringify(x.query) === JSON.stringify(query));
        const updatedItems = [...(store.items?.() || [])];
        if (!!indexOfQuery && indexOfQuery > -1) {
          updatedItems[indexOfQuery] = {
            data: undefined,
            lastUpdatedDate: undefined,
            loading: true,
            query: query,
          };
        } else {
          updatedItems.push({
            data: undefined,
            lastUpdatedDate: undefined,
            loading: true,
            query: query,
          });
        }
        patchState(store, {
          items: updatedItems,
        });
        const newData = await firstValueFrom(
          httpClientItem
            .readAllWithFilters(query.pagedRequest, query.type, query.itemType, query.isStockable, query.isAverageCost, query.isBlocked)
            .pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded || !newData.data?.results || newData.data.results.length === 0) {
          patchState(store, {
            items: INITIAL_ITEMS_STATE.items,
          });
          return;
        }
        const dataFromApi = {
          data: newData.data.results,
          lastUpdatedDate: moment(),
          loading: false,
          query: query,
          hasNextPage: newData.data?.rowCount !== newData.data?.lastRowOnPage,
        };
        // gjejme indeksin e queryt => sepse indexi me lart mund te mos jete gjendur nese query ska qene ne state
        // sipas rastit shtojme element te ri ne state ose zevendesojme elementin e queryt ekzistues.
        const lastQueryIndex = (store.items?.() || []).findIndex(x => JSON.stringify(x.query) === JSON.stringify(query));
        if (!!lastQueryIndex && lastQueryIndex > -1) {
          const updatedItems = [...(store.items?.() || [])];
          updatedItems[lastQueryIndex] = dataFromApi;
          patchState(store, {
            items: updatedItems.map(item => ({
              ...item,
              loading: false,
            })),
          });
        } else {
          patchState(store, {
            items: [dataFromApi, ...(store.items?.() || [])],
          });
        }
      },
      clearItems: () => {
        patchState(store, INITIAL_ITEMS_STATE);
      },
      clearItem: () => {
        patchState(store, { items: undefined });
      },
      loadIngredients: async (itemNos: string[]) => {
        if (itemNos.length === 0) return;
        patchState(store, { ingredientsLoading: true });

        const existingIngredients = (store?.items() ?? [])
          .filter(x => x.data)
          .map(x => x.data!)
          .flat()
          .filter(x => x.no && itemNos.includes(x.no));

        if (existingIngredients.length === itemNos.length) {
          patchState(store, { ingredientsLoading: false });
          return;
        }

        const notExistingIngredients = itemNos
          .filter(x => !existingIngredients.map(y => y.no).includes(x))
          .map(
            x =>
              new DynamicFilter({
                property: 'No',
                value: x,
                comparison: ComparisonOperator.Equal,
                ignoreCase: false,
              }),
          );
        if (notExistingIngredients.length === 0) {
          patchState(store, { ingredientsLoading: false });
          return;
        }
        const query = <ITEMS_QUERY>{
          pagedRequest: new PagedRequest({
            page: {
              number: 1,
              size: notExistingIngredients.length,
            },
            filters: notExistingIngredients,
            isOrOperator: true,
          }),
        };
        const newData = await firstValueFrom(httpClientItem.readAllWithFilters(query.pagedRequest).pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data?.results || newData.data.results.length === 0) {
          patchState(store, { ingredientsLoading: false });
          return;
        }

        const dataFromApi = {
          data: newData.data.results,
          lastUpdatedDate: moment(),
          loading: false,
          query: query,
        };

        const updatedItems = [...(store.items?.() || [])];
        updatedItems.push(dataFromApi);
        patchState(store, {
          items: updatedItems.map(item => ({
            ...item,
            loading: false,
          })),
          ingredientsLoading: false,
        });
      },
      loadItemsName: async (force_skip: boolean = false) => {
        if (!force_skip) {
          const items = store.itemsNoDescriptionPair();
          const existingData = GetStateData(items, moment(), EXPIRE_API_SECONDS.ITEM_DESCRIPTION_PAIR, configurationStore.skipCache().data);
          if (existingData) return;
        }
        const newData = await firstValueFrom(httpClientItem.pairNoDescription().pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data) return;
        const dataFromApi = {
          data: newData.data,
          lastUpdatedDate: moment(),
          loading: false,
        };

        patchState(store, {
          itemsNoDescriptionPair: dataFromApi,
        });
      },
      loadVats: async (force_skip: boolean = false) => {
        if (!force_skip) {
          const vats = store.vats();
          const existingData = GetStateData(vats, moment(), EXPIRE_API_SECONDS.VATS, configurationStore.skipCache().data);
          if (existingData) return;
        }
        const newData = await firstValueFrom(
          httpClientVat
            .getAllVatsOrFiltered(
              new VatGetAllOrFilteredRequest({
                page: new DynamicPage({ number: 1, size: 999 }),
              }),
            )
            .pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded || !newData.data) return;
        const dataFromApi = {
          data: newData.data.results,
          lastUpdatedDate: moment(),
          loading: false,
        };

        patchState(store, {
          vats: dataFromApi,
        });
      },

      loadUnitsOfMeasure: async (force_skip: boolean = false) => {
        if (!force_skip) {
          const unitOfMeasureList = store.unitOfMeasureList!();
          const existingData = GetStateData(unitOfMeasureList, moment(), EXPIRE_API_SECONDS.UNIT_OF_MEASURES, configurationStore.skipCache().data);
          if (existingData) return;
        }
        const newData = await firstValueFrom(
          httpClientUnitOfMeasure
            .getAllUnitOfMeasuresOrFiltered(
              new UnitOfMeasureGetAllOrFilteredRequest({
                page: new DynamicPage({ number: 1, size: 99999 }),
              }),
            )
            .pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded || !newData.data) return;
        const dataFromApi = {
          data: newData.data.results,
          lastUpdatedDate: moment(),
          loading: false,
        };

        patchState(store, {
          unitOfMeasureList: dataFromApi,
        });
      },
      clearUnitOfMeasureList: async () => {
        patchState(store, {
          unitOfMeasureList: undefined,
        });
      },
    }),
  ),

  withDevtools('ItemsStore'),
  withStorageSync('items'),
);
